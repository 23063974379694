<script setup>
import {reactive, ref} from "vue";

const siteUrl = document.querySelector('meta[name="site-url"]').content;
const props = defineProps({
  registerSuccess: {
    type: Boolean,
    required: true,
  },
});

// ログイン情報
const loginInputs = reactive({
  email: '',
  password: ''
});

// ログインエラー
const loginErrors = reactive({
  _: '',
  email: '',
  password: ''
});

// 連打防止用
const lockLogin = ref(false);

// ログインを試みる
const tryLogin = async () => {
  // ロック中は何もしない
  if (lockLogin.value) {
    return;
  }

  //  1 秒ロックする
  lockLogin.value = true;
  setTimeout(() => {
    lockLogin.value = false;
  }, 1000);

  // エラーを初期化
  loginErrors._ = '';
  loginErrors.email = '';
  loginErrors.password = '';

  // メールアドレスは必須
  if ( ! loginInputs.email) {
    loginErrors.email = 'メールアドレスを入力してください';
  }

  // メールアドレスの形式をチェック
  else if ( ! /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(loginInputs.email)) {
    loginErrors.email = 'メールアドレスの形式が正しくありません';
  }

  // パスワードは必須
  if ( ! loginInputs.password) {
    loginErrors.password = 'パスワードを入力してください';
  }

  // エラーが存在すれば中断
  if (loginErrors.email || loginErrors.password) {
    return;
  }

  // 送信データを作成
  const body = new FormData();
  body.append('email', loginInputs.email);
  body.append('password', loginInputs.password);

  // 新規作成 or 更新を試みる
  try {
    // サーバに問い合わせ
    const response = await fetch(siteUrl+'login/auth', {
      method: 'post',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
      },
      body
    });

    // JSON 形式で返却されたデータを取得
    const data = await response.json();

    // もしエラーが発生した場合はエラーを表示
    if ( ! response.ok) {
      throw data;
    }

    // ログイン先にリダイレクト
    if ('url' in data) {
      window.location.href = data.url;
    }
    else {
      window.location.reload();
    }
  }

    // エラー発生
  catch (error) {
    // エラーの内容をコンソールに表示
    console.log(error);

    // error が文字列だったら _ にセット
    if (typeof error === 'string') {
      loginErrors._ = error;
    }

    // error が配列なら「、」で連結して _ にセット
    else if (Array.isArray(error)) {
      loginErrors._ = error.join('、');
    }

    // error がオブジェクト
    else if (error !== null && typeof error === 'object') {
      // キーを集める
      const keys = Object.keys(error);

      // email, password 以外のメッセージ
      const messages = [];

      // キーごとに検証
      for (const key of keys) {
        // 値が配列の場合
        if (Array.isArray(error[key])) {
          // email or password
          if (key === 'email' || key === 'password') {
            loginErrors[key] === error[key].join('、');
          }

          // それ以外の場合は messages に格納
          else {
            messages.push(...error[key]);
          }
        }

        // そうではない場合は string として扱う
        else {
          if (key === 'email' || key === 'password') {
            loginErrors[key] = error[key].toString();
          }
          else {
            messages.push(error[key].toString());
          }
        }
      }

      // messages が存在する場合は _ にセット
      if (messages.length > 0) {
        loginErrors._ = messages.join('、');
      }
    }

    // 予想されていないエラー
    else {
      loginErrors._ = '予期しないエラーが発生しました。恐れ入りますが、しばらくしてからログインして下さい。';
    }
  }
};
</script>

<template>
  <div class="lyColumn gOnlyLoginModalWrapper">
    <p
      v-if="registerSuccess"
      class="lyMb8 pFont12 pFontBold pFontRed"
    >新規登録が完了しました。登録した情報でログインしてください。</p>
    <div
      class="lyHorizontalBwBreak lyMb16"
      style="flex-grow:1"
    >
      <div class="lyW100 lyTbW49 lyPd16 lyMb16 lyTbMb0 pFont14 pBackColorWhite pBorderRadius4px">
        <section>
          <h5 class="lyTextCnt lyMb8 pFont16 pFontBold">SNSでログイン/アカウント登録する</h5>
          <p class="lyMb16 pFont14">ログインすると<a :href="siteUrl+'terms'">利用規約</a>および<a href="https://commitgrowth.co.jp/privacy_policy/" target="_blank">プライバシーポリシー</a>に同意したことになります。</p>
          <ul>
            <li class="lyPt8 lyPb8">
              <a
                :href="siteUrl+'login/line'"
                class="lyHorizontal lyW100 pFontWhite pBackColorGreen pBorderRadius4px pHoverClearBlack"
              >
                <p class="lyShrinkNone lyHvCnt lyPt12 lyPb12 lyW48px pBorderRight1pxSolidGray4">
											<span class="lyW24px">
												<img :src="siteUrl+'images/loginSnsLine.svg'" width="24" height="24" loading="lazy" alt="LINE">
											</span>
                </p>
                <p class="lyHvCnt lyW100 pFontBold">LINEでログイン</p>
              </a>
            </li>
            <li class="lyPt8 lyPb8">
              <a
                :href="siteUrl+'login/twitter'"
                class="lyHorizontal lyW100 pFontWhite pBackColorBlack pBorderRadius4px pHoverClearWhite"
              >
                <p class="lyShrinkNone lyHvCnt lyPt12 lyPb12 lyW48px pBorderRight1pxSolidGray4">
											<span class="lyW24px">
												<img :src="siteUrl+'images/loginSnsX.svg'" width="24" height="24" loading="lazy" alt="X">
											</span>
                </p>
                <p class="lyHvCnt lyW100 pFontBold">Xでログイン</p>
              </a>
            </li>
            <li class="lyPt8 lyPb8">
              <a
                :href="siteUrl+'login/yahoo'"
                class="lyHorizontal lyW100 pFontWhite pBackColorYahooRed pBorderRadius4px pHoverClearBlack"
              >
                <p class="lyShrinkNone lyHvCnt lyPt12 lyPb12 lyW48px pBorderRight1pxSolidGray4">
											<span class="lyW24px">
												<img :src="siteUrl+'images/loginSnsYahoo.svg'" width="24" height="24" loading="lazy" alt="X">
											</span>
                </p>
                <p class="lyHvCnt lyW100 pFontBold">Yahoo!JAPAN IDでログイン</p>
              </a>
            </li>
          </ul>
        </section>
      </div>
      <div class="lyW100 lyTbW49 lyPd16 pFont14 pBackColorWhite pBorderRadius4px">
        <section>
          <h5 class="lyTextCnt lyMb16 pFont16 pFontBold">登録メールアドレスでログイン</h5>
          <p
            v-if="loginErrors._ !== ''"
            class="lyMt4 pFont10 pFontMedium pFontRed"
          >{{loginErrors._}}</p>
          <div class="lyPt4 lyPb4 lyMb16">
            <div class="lyPt4 lyPb4">
              <div :class="{pPopFormWrap: true, pFont16: true, isNegative: loginErrors.email !== ''}">
                <input
                  v-model="loginInputs.email"
                  type="email"
                  id="userEmailAddress"
                  class="pPopForm"
                  placeholder=" "
                  required=""
                  style="box-shadow: none"
                  @keyup.enter="tryLogin"
                >
                <label for="userEmailAddress" class="pPopFormLabel">メールアドレス（半角）</label>
              </div>
              <p
                v-if="loginErrors.email !== ''"
                class="lyMt4 pFont10 pFontMedium pFontRed"
              >{{loginErrors.email}}</p>
            </div>
            <div class="lyPt4 lyPb4">
              <div :class="{pPopFormWrap: true, pFont16: true, isNegative: loginErrors.email !== ''}">
                <input
                  v-model="loginInputs.password"
                  type="password"
                  id="userPassword"
                  class="pPopForm"
                  placeholder=" "
                  required=""
                  style="box-shadow: none"
                  @keyup.enter="tryLogin"
                >
                <label for="userPassword" class="pPopFormLabel">パスワード</label>
              </div>
              <p
                v-if="loginErrors.password !== ''"
                class="lyMt4 pFont10 pFontMedium pFontRed"
              >{{loginErrors.password}}</p>
            </div>
            <p>
              <a
                :href="siteUrl+'forget'"
                class="lyHorizontalEnd lyVerticalCnt lyPt4 pFont12 pFontMedium"
              >
                パスワードを忘れた方はこちら
                <span class="lyBlock lyMl2 lyW12px">
											<img :src="siteUrl+'images/iconRightArrowBlue.svg'" width="12" height="12" loading="lazy" alt="">
										</span>
              </a>
            </p>
          </div>
          <button
            type="button"
            class="lyHvCnt lyW100 lyPt12 lyPb12 lyTextCnt pFont14 pFontBold pFontGreen pBorder1pxSolidGreen pBorderRadius2px"
            @click="tryLogin"
          >ログイン</button>
        </section>
      </div>
    </div>
  </div>
</template>
