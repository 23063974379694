<script setup>
const siteUrl = document.querySelector('meta[name="site-url"]').content;
const emits = defineEmits(['request-change']);
</script>

<template>
  <div>
    <div class="lyHorizontalBwBreak lyMb16">
      <div class="lyW100 lyTbW49 lyPd16 lyMb16 lyTbMb0 pFont14 pBackColorWhite pBorderRadius4px">
        <section>
          <h5 class="lyTextCnt lyMb8 pFont16 pFontBold">SNSで新規アカウント登録する</h5>
          <p class="lyMb16 pFont14">アカウント登録すると<a :href="siteUrl+'terms'">利用規約</a>および<a href="https://commitgrowth.co.jp/privacy_policy/" target="_blank">プライバシーポリシー</a>に同意したことになります。</p>
          <ul>
            <li class="lyPt8 lyPb8">
              <a
                :href="siteUrl+'login/line'"
                class="lyHorizontal lyW100 pFontWhite pBackColorGreen pBorderRadius4px pHoverClearBlack"
              >
                <p class="lyShrinkNone lyHvCnt lyPt12 lyPb12 lyW48px pBorderRight1pxSolidGray4">
											<span class="lyW24px">
												<img :src="siteUrl+'images/loginSnsLine.svg'" width="24" height="24" loading="lazy" alt="LINE">
											</span>
                </p>
                <p class="lyHvCnt lyW100 pFontBold">LINEでログイン</p>
              </a>
            </li>
            <li class="lyPt8 lyPb8">
              <a
                :href="siteUrl+'login/twitter'"
                class="lyHorizontal lyW100 pFontWhite pBackColorBlack pBorderRadius4px pHoverClearWhite"
              >
                <p class="lyShrinkNone lyHvCnt lyPt12 lyPb12 lyW48px pBorderRight1pxSolidGray4">
											<span class="lyW24px">
												<img :src="siteUrl+'images/loginSnsX.svg'" width="24" height="24" loading="lazy" alt="X">
											</span>
                </p>
                <p class="lyHvCnt lyW100 pFontBold">Xでログイン</p>
              </a>
            </li>
            <li class="lyPt8 lyPb8">
              <a
                :href="siteUrl+'login/yahoo'"
                class="lyHorizontal lyW100 pFontWhite pBackColorYahooRed pBorderRadius4px pHoverClearBlack"
              >
                <p class="lyShrinkNone lyHvCnt lyPt12 lyPb12 lyW48px pBorderRight1pxSolidGray4">
											<span class="lyW24px">
												<img :src="siteUrl+'images/loginSnsYahoo.svg'" width="24" height="24" loading="lazy" alt="X">
											</span>
                </p>
                <p class="lyHvCnt lyW100 pFontBold">Yahoo!JAPAN IDでログイン</p>
              </a>
            </li>
          </ul>
        </section>
      </div>
      <div class="lyW100 lyTbW49 lyPd16 pFont14 pBackColorWhite pBorderRadius4px">
        <section>
          <h5 class="lyTextCnt lyMb16 pFont16 pFontBold">メールアドレスでアカウント登録する</h5>
          <p class="lyMaxW400px lyBlockCnt">
            <button
              type="button"
              class="lyHvCnt lyW100 lyPt12 lyPb12 lyTextCnt pFont14 pFontBold pFontWhite pBackColorGreen pBorderRadius2px pHoverSlideChange"
              @click="emits('request-change', 'email')"
            >新規登録</button>
          </p>
        </section>
      </div>
    </div>
  </div>
</template>
