<script setup>
import {onMounted, ref} from 'vue';
import ModalSupport from "./support/ModalSupport.vue";
import ModalSend from "./send/ModalSend.vue";
import ModalMain from "./main/ModalMain.vue";

// メインモーダルで何を表示するか
// '': 表示しない
// 'login': ログイン
// 'email': 新規会員登録(メールアドレス入力)
// 'name': 新規会員登録(ニックネーム)
// 'children': 新規会員登録(子供)
// 'categories': 新規会員登録(興味のあるカテゴリ)
// 'confirm': 新規会員登録(確認画面)
const mainModal = ref('');

// メールアドレス初期値
const initEmail = ref('');

// サポートモーダルで表示する内容
// '': 表示しない
// 'bonus': ボーナスポイント
// 'name': ニックネームについて
const supportModal = ref('');

// 送信済モーダルを表示するか
const isSendModal = ref(false);

// 新規会員登録完了後にリダイレクトしてきた際に true となる登録完了フラグ
const registerSuccess = ref(false);

// コメント UI から開かれた場合 true になる
const isFromComment = ref(false);

// URL パラメータからモーダルを表示する
onMounted(() => {
  // 既にログインしている場合は何もしない
  if (typeof loginUserId !== 'undefined' && Number(loginUserId) !== 0) {
    return;
  }

  // URL パラメータを取得
  const urlParams = new URLSearchParams(window.location.search);

  // entry パラメータがあるかつ、 login の場合はモーダルを表示
  if (urlParams.has('entry') && urlParams.get('entry') === 'login') {
    mainModal.value = 'login';
  }

  // entry パラメータがあるかつ、 success の場合は新規会員登録完了後としてモーダルを表示
  if (urlParams.has('entry') && urlParams.get('entry') === 'success') {
    mainModal.value = 'only-login';
    registerSuccess.value = true;
  }

  // entry パラメータがあるかつ、 email の場合はモーダルを表示
  if (urlParams.has('entry') && urlParams.get('entry') === 'email') {
    mainModal.value = 'email';
    initEmail.value = urlParams.has('email') ? urlParams.get('email') : '';
  }

  // entry パラメータがあるかつ、 name の場合はモーダルを表示
  if (urlParams.has('entry') && urlParams.get('entry') === 'name') {
    mainModal.value = 'name';
  }
});

// ログインボタンによるモーダル表示
window.addEventListener('openLoginModal', () => {
  mainModal.value = 'login';
  isFromComment.value = false;
});
window.addEventListener('openLoginModalComment', () => {
  mainModal.value = 'login';
  isFromComment.value = true;
});
window.addEventListener('openOnlyLoginModal', () => {
  mainModal.value = 'only-login';
  isFromComment.value = false;
});

// 新規会員登録ボタンによるモーダル表示
window.addEventListener('openEntryModal', () => {
  mainModal.value = 'only-entry';
});
</script>

<template>
  <div>
    <ModalMain
      :modal-type="mainModal"
      :init-email="initEmail"
      :is-from-comment="isFromComment"
      :register-success="registerSuccess"
      @request-change="mainModal = $event"
      @request-send="isSendModal = true"
      @request-support="supportModal = $event"
    />
    <ModalSend
      :is-show="isSendModal"
      @request-close="isSendModal = false, mainModal = ''"
    />
    <ModalSupport
      :modal-type="supportModal"
      @request-close="supportModal = ''"
    />
  </div>
</template>
